import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BulkEditModalComponent } from './bulk-edit-modal/bulk-edit-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { ModalComponent } from './modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modalService: BsModalService,
    private dialogService: MatDialog
  ) { }

  showMessage(title: string, message: string) {
    const initialState = {
      title,
      message
    };
    return this.showModal(MessageBoxComponent, {
      class: 'madero-style modal-sm',
      initialState
    });
  }

  confirm(title: string, message: string, confirmButton = 'Ok', cancelButton = 'Cancel', style = 'madero-style', returnFocusId = '') {
    const initialState = {
      title,
      message,
      confirmButton,
      cancelButton,
      returnFocusId
    };
    return this.showModal(ConfirmModalComponent, {
      class: style,
      initialState
    });
  }

  confirmDanger(title: string, message: string, confirmButton = 'Ok', cancelButton = 'Cancel') {
    const initialState = {
      confirmButtonClass: 'btn-danger',
      title,
      message,
      confirmButton,
      cancelButton
    };
    return this.showModal(ConfirmModalComponent, {
      initialState
    });
  }

  showBulkEditModal(title: string, baseModel: any, component: any): Promise<any> {
    return this.showMediumModal(BulkEditModalComponent, {
      initialState: {
        baseModel,
        title,
        component
      }
    });
  }

  showModal(component: { new(...args: any[]): ModalComponent }, config?) {
    const modalInstance = this.modalService.show(component, Object.assign({}, {
      // default madero modal size
      class: 'madero-style'
    }, config));

    this.modalService.onHidden.subscribe(() => {
      const focusElementId = config?.initialState?.returnFocusId;
      const focusElement = focusElementId ? document.getElementById(focusElementId) : null;

      if (focusElement) {
        focusElement.focus();
      }
    });

    return modalInstance.content.promise;
  }

  showMediumModal(component: { new(...args: any[]): ModalComponent }, config?) {
    return this.showModal(component, Object.assign({}, {
      class: 'madero-style modal-md'
    }, config));
  }

  showLargeModal(component: { new(...args: any[]): ModalComponent }, config?) {
    return this.showModal(component, Object.assign({}, {
      class: 'madero-style modal-lg'
    }, config));
  }

  showLegacyModal(component: { new(...args: any[]): ModalComponent }, config?) {
    return this.showModal(component, Object.assign({}, {
      class: ''
    }, config));
  }

  showMediumDialog<T>(component: ComponentType<T>, config?) {
    return this.dialogService.open(component, {
      ...config,
      panelClass: 'modal-md',
    });
  }

}

angular.module('risevision.common.components')
  .factory('ngModalService', downgradeInjectable(ModalService));
