import { Component, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';

import * as _ from 'lodash';


import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { UrlFieldValidators } from 'src/app/components/url-field/url-field-validators';
import { PresentationsService } from 'src/app/editor/services/presentations.service';
import { PlaylistService } from '../../services/playlist.service';

@Component({
  selector: 'playlist-item',
  templateUrl: './playlist-item.component.html',
  styleUrls: ['./playlist-item.component.scss']
})
export class PlaylistItemComponent implements OnInit {

  transitionTypes = PlaylistService.TRANSITION_TYPES;
  companyId = this.companyStateService.getSelectedCompanyId();

  playlistItem: any = null;
  _playlistItem;

  isNew;
  playUntilDoneSupported;
  loadingTemplate;

  playlistItemForm: NgForm;
  @ViewChild('playlistItemForm')
  public set form(value: NgForm) {
    this.playlistItemForm = value;
    this.setValidators();
  }

  protected modalRef = inject(MatDialogRef<PlaylistItemComponent>);

  constructor(
    private companyStateService: CompanyStateService,
    private playlistService: PlaylistService,
    private presentationsService: PresentationsService,
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) data: { playlistItem: any }
  ) {
    this.playlistItem = data.playlistItem;
  }

  ngOnInit(): void {
    this._playlistItem = _.cloneDeep(this.playlistItem);
    this.isNew = this.playlistService.isNew(this.playlistItem);

    this.configurePlayUntilDone();
  }

  setValidators(): void {
    setTimeout(() => {
      this.playlistItemForm.form.controls['urlField'].addValidators(
        Validators.compose([
          Validators.required,
          UrlFieldValidators.url,
          UrlFieldValidators.http,
          UrlFieldValidators.noPreviewUrl,
        ])
      );
      this.playlistItemForm.form.controls['urlField'].setAsyncValidators(
        UrlFieldValidators.responseHeader(this.httpClient)
      );
      this.playlistItemForm.form.updateValueAndValidity();
    });
  }

  selectPresentation () {
    this.presentationsService.openPresentationSelector()
      .then((presentationDetails) => {
        this._playlistItem.objectReference = presentationDetails[0];
        this._playlistItem.presentationType = presentationDetails[2];
        this.configurePlayUntilDone();
      });
  }

  configurePlayUntilDone() {
    if (this._playlistItem.type === 'url') {
      return;
    }

    this.playUntilDoneSupported = true;
    this.loadingTemplate = true;

    this.presentationsService.getPresentationCached(this._playlistItem.objectReference)
      .then((presentation) => {
        return this.playlistService.initPlayUntilDone(this._playlistItem, presentation, this.isNew);
      })
      .then((playUntilDone) => {
        this.playUntilDoneSupported = playUntilDone;
      })
      .finally(() => {
        this.loadingTemplate = false;
      });
  }

  saveDisabled() {
    return this.playlistItemForm && ((this.playlistItemForm.controls['itemDuration'] && this.playlistItemForm.controls['itemDuration'].invalid) ||
      (this.playlistItemForm.controls['urlField'] && this.playlistItemForm.controls['urlField'].invalid &&
      (this.playlistItemForm.controls['urlField'].dirty || this.isNew)));
  }

  save () {
    if (this.saveDisabled()) {
      console.error('form not valid');
      return;
    }
    _.assign(this.playlistItem, this._playlistItem);
    this.playlistService.updatePlaylistItem(this.playlistItem);
    this.modalRef.close();
  }

  dismiss () {
    this.modalRef.close();
  }
}